import { gql } from '@apollo/client';
import client from './common.api';
import { BillingAccountResult } from './types';

type CardId = string;
type Month = string;
type Year = string;

export enum BillingAccountStatus {
  Ok = 'OK',
  ChargesDeclined = 'CHARGES_DECLINED',
  Unknown = 'UNKNOWN_ACCOUNT',
}

export type TransactionSegment = {
  userId: string;
  adTypeId: string;
  adId: string;
  catalogId: string;
  transactionId: string;
  segmentStartsAtUtcEpoch: number;
  segmentEndsAtUtcEpoch: number;
  adCreatedAtUtcEpoch: number;
  adCostPerDay: number;
  totalCost: number;
  timezone: string;
};
export type DateMappedTransactionSegments = Record<
  Year,
  Record<Month, TransactionSegment[]>
>;

export type EditCardMutationInput = {
  companyID: string;
  cardId: CardId;
  expirationMonth?: number;
  expirationYear?: number;
  addressCity?: string;
  addressCountry?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressState?: string;
  addressZip?: string;
};

export type ReplaceCardMutationInput = {
  companyID: string;
  oldCardId: CardId;
  newCardId: CardId;
};

export type PaymentCard = {
  paymentId: CardId;
  brand: string;
  cardLast4: string;
  expirationMonth: number;
  expirationYear: number;
  expired: boolean;
  error?: string;
};

export type BillingAccount = {
  userId: string;
  cards: PaymentCard[];
  primaryCardId?: CardId;
  accountStatus: BillingAccountStatus;
};

type MutationResponse = {
  code: number;
  success: boolean;
  message: string;
};

export type BillingAccountMutationResponse = MutationResponse & {
  billing: BillingAccount;
};

const BILLING_ACCOUNT_ALL_FIELDS = `
userId,
primaryCardId,
accountStatus
cards {paymentId,brand,cardLast4,expirationMonth,expirationYear,expired,error}`;
const ACCOUNT_CARDS_ALL_FIELDS =
  'paymentId,brand,cardLast4,expirationMonth,expirationYear,expired,error';

export async function createStripeSetupIntent(): Promise<object | null> {
  type BillingAccountQuery = {
    createStripeSetupIntent: object;
  };
  const query = gql`
    {
      createStripeSetupIntent
    }
  `;
  const result = await client.query<BillingAccountQuery>({
    query,
    fetchPolicy: 'no-cache',
  });
  return result.data?.createStripeSetupIntent ?? null;
}

export async function getBillingAccount(
  companyID: string
): Promise<BillingAccountResult> {
  type BillingAccountQuery = {
    billing: BillingAccountResult;
  };
  const query = gql`
    query GetBillingAccount($companyID: String) {
        billing(companyID: $companyID) {
            __typename
            ... on BillingAccount {
              ${BILLING_ACCOUNT_ALL_FIELDS}
            }
            ... on BillingAccountNotFound {
              reason
            }
        }
    }
  `;
  const result = await client.query<BillingAccountQuery>({
    query,
    variables: {
      companyID,
    },
    // TODO: once we pul fetching of this data in react should not need this line
    fetchPolicy: 'no-cache',
  });
  return result.data?.billing ?? null;
}

export async function createBillingAccount(
  companyID: string
): Promise<BillingAccount | null> {
  type CreateBillingAccountMutation = {
    createBillingAccount: BillingAccountMutationResponse;
  };
  const mutation = gql`
    mutation CreateBillingAccount($companyID: String) {
      createBillingAccount(companyID: $companyID) {
          billing {
              ${BILLING_ACCOUNT_ALL_FIELDS}
          }
      }
    }
  `;
  const result = await client.mutate<CreateBillingAccountMutation>({
    mutation,
    variables: {
      companyID,
    },
  });
  return result.data?.createBillingAccount.billing ?? null;
}

export async function getTransactions(
  lowerBoundsISO8601: string,
  upperBoundsISO8601: string,
  timezone: string,
  companyID: string
): Promise<DateMappedTransactionSegments | null> {
  type TransactionsQuery = {
    transactions: DateMappedTransactionSegments;
  };
  const query = gql`
    query GetTransactions(
      $lowerBoundsISO8601: String!
      $upperBoundsISO8601: String!
      $timezone: String!
      $companyID: String
    ) {
      transactions(
        lowerBoundsISO8601: $lowerBoundsISO8601
        upperBoundsISO8601: $upperBoundsISO8601
        timezone: $timezone
        companyID: $companyID
      )
    }
  `;
  const result = await client.query<TransactionsQuery>({
    query,
    variables: {
      lowerBoundsISO8601,
      upperBoundsISO8601,
      timezone,
      companyID,
    },
  });
  return result.data?.transactions ?? null;
}

export async function addCard(
  cardId: CardId,
  companyID: string
): Promise<BillingAccount | null> {
  type AddCardMutation = {
    addCard: BillingAccountMutationResponse;
  };
  const mutation = gql`
    mutation AddCard($cardId: String!, $companyID: String) {
      addCard(cardId: $cardId, companyID: $companyID) {
        billing {
          cards {
						${ACCOUNT_CARDS_ALL_FIELDS}
					},
          primaryCardId
        }
      }
    }
  `;
  const result = await client.mutate<AddCardMutation>({
    mutation,
    variables: {
      cardId,
      companyID,
    },
  });
  return result.data?.addCard.billing ?? null;
}

export async function removeCard(
  cardId: CardId,
  companyID: string
): Promise<BillingAccount | null> {
  type RemoveCardMutation = {
    removeCard: BillingAccountMutationResponse;
  };
  const mutation = gql`
    mutation RemoveCard($cardId: String!, $companyID: String) {
      removeCard(cardId: $cardId, companyID: $companyID) {
        billing {
          cards {
						${ACCOUNT_CARDS_ALL_FIELDS}
					},
          primaryCardId
        }
      }
    }
  `;
  const result = await client.mutate<RemoveCardMutation>({
    mutation,
    variables: {
      cardId,
      companyID,
    },
  });
  return result.data?.removeCard.billing ?? null;
}

export async function editCard(
  editCardParams: EditCardMutationInput
): Promise<BillingAccount | null> {
  type EditCardMutation = {
    editCard: BillingAccountMutationResponse;
  };
  const mutation = gql`
    mutation EditCard($editCardInput: EditCardMutationInput!) {
      editCard(editCardInput: $editCardInput) {
        billing {
          cards {
						${ACCOUNT_CARDS_ALL_FIELDS}
					},
          primaryCardId
        }
      }
    }
  `;
  const variables = {
    editCardInput: editCardParams,
  };
  const result = await client.mutate<EditCardMutation>({
    mutation,
    variables,
  });
  return result.data?.editCard.billing ?? null;
}

export async function replaceCard(
  replaceCardParams: ReplaceCardMutationInput
): Promise<BillingAccount | null> {
  type ReplaceCardMutation = {
    replaceCard: BillingAccountMutationResponse;
  };
  const mutation = gql`
    mutation ReplaceCard($replaceCardInput: ReplaceCardMutationInput!) {
      replaceCard(replaceCardInput: $replaceCardInput) {
        billing {
          cards {
						${ACCOUNT_CARDS_ALL_FIELDS}
					},
          primaryCardId
          accountStatus
        }
      }
    }
  `;
  const variables = {
    replaceCardInput: replaceCardParams,
  };
  const result = await client.mutate<ReplaceCardMutation>({
    mutation,
    variables,
  });
  return result.data?.replaceCard.billing ?? null;
}

export async function setPrimaryCardId(
  cardId: CardId,
  companyID: string
): Promise<BillingAccount | null> {
  type SetPrimaryCardIdMutation = {
    setPrimaryCardId: BillingAccountMutationResponse;
  };
  const mutation = gql`
    mutation SetPrimaryCard($cardId: String!, $companyID: String) {
      setPrimaryCardId(cardId: $cardId, companyID: $companyID) {
        billing {
          primaryCardId
        }
      }
    }
  `;
  const result = await client.mutate<SetPrimaryCardIdMutation>({
    mutation,
    variables: {
      cardId,
      companyID,
    },
  });
  return result.data?.setPrimaryCardId.billing ?? null;
}
