import React from 'react';
import { useLocation } from 'react-router-dom';

import CreditCard from '@material-ui/icons/CreditCard';
import Group from '@material-ui/icons/Group';
import SupervisedUserCircleRounded from '@material-ui/icons/SupervisedUserCircleRounded';
import VisibilityRounded from '@material-ui/icons/VisibilityRounded';
// import Settings from '@material-ui/icons/Settings';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction';

import * as S from './SwayMeeAppSideMenu.styled';

import { useRouting, useString } from '../../hooks';
import Routes from '../../routes';

interface AppSideMenuListItem {
  route: Routes;
  icon: React.ReactNode;
  disabled?: boolean;
  secondaryIcon?: React.ReactNode;
}

type MenuItemRecords = Record<string, AppSideMenuListItem>[];

export interface AppSideMenuListProps {
  onClose?: () => void;
}

const AppListContent: MenuItemRecords = [
  {
    'Ad Campaign': {
      route: Routes.AdCampaign,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="currentColor"
        >
          <path d="M720-440v-80h160v80H720Zm48 280-128-96 48-64 128 96-48 64Zm-80-480-48-64 128-96 48 64-128 96ZM200-200v-160h-40q-33 0-56.5-23.5T80-440v-80q0-33 23.5-56.5T160-600h160l200-120v480L320-360h-40v160h-80Zm240-182v-196l-98 58H160v80h182l98 58Zm120 36v-268q27 24 43.5 58.5T620-480q0 41-16.5 75.5T560-346ZM300-480Z" />
        </svg>
      ),
    },
    'Ad Insights': {
      route: Routes.Analytics,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="currentColor"
        >
          <path d="M120-120v-80l80-80v160h-80Zm160 0v-240l80-80v320h-80Zm160 0v-320l80 81v239h-80Zm160 0v-239l80-80v319h-80Zm160 0v-400l80-80v480h-80ZM120-327v-113l280-280 160 160 280-280v113L560-447 400-607 120-327Z" />
        </svg>
      ),
    },
    'My Business': {
      route: Routes.MyBusiness,
      icon: <SupervisedUserCircleRounded />,
    },
    Billing: { route: Routes.Billing, icon: <CreditCard /> },
    Team: { route: Routes.Team, icon: <Group /> },
  },
  // {
  //   Settings: { route: Routes.Settings, icon: <Settings /> },
  // },
];

export default function SwayMeeAppSideMenuList({
  onClose,
}: AppSideMenuListProps) {
  const [goTo] = useRouting();
  const location = useLocation();
  const path = location.pathname;
  const [currentPath] = useString(path);

  React.useEffect(() => {
    currentPath.setValue(path);
  }, [path, currentPath]);

  // handle item click thunk
  const handleListItemClick =
    (route: Routes) =>
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onClose && onClose();
      goTo(route);
    };

  return (
    <div>
      <S.List>
        {AppListContent.map((section, idx) => {
          return (
            <React.Fragment key={`SideMenuSection${idx}`}>
              {Object.entries(section).map(([desc, routes], _) => {
                const route = routes.route;
                return (
                  <S.ListItem
                    button={!routes.disabled}
                    key={`MenuListItem${desc}`}
                    selected={currentPath.value.startsWith(route)}
                    onClick={handleListItemClick(route)}
                    style={{
                      opacity: !!routes.disabled ? 0.6 : 1,
                      pointerEvents: routes.disabled ? 'none' : 'all',
                      userSelect: 'none',
                    }}
                  >
                    <ListItemIcon>{routes.icon}</ListItemIcon>
                    <ListItemText primary={desc} />
                    {!!routes.secondaryIcon && (
                      <ListItemSecondaryAction style={{ display: 'flex' }}>
                        {routes.secondaryIcon}
                      </ListItemSecondaryAction>
                    )}
                  </S.ListItem>
                );
              })}
              {idx + 1 < AppListContent.length && (
                <Divider orientation="horizontal" variant="fullWidth" />
              )}
            </React.Fragment>
          );
        })}
      </S.List>
    </div>
  );
}
